import "./Experiences.css";

function Experience() {
  return (
    <div id="experiences">
      <h1 id="title_experiences">Experiences Professionnelles</h1>
      <div class="line-container">
        <span class="middle-line2"></span>
      </div>
      <div id="content_exp">
        <div class="item">
          <h4 class="years">4 mois - 2019</h4>
          <h1 class="activity2">Stage</h1>
          <h5 class="description">
            Agent de Support <br/>d'Informatique de Proximité
          </h5>
          <h3>📍 BNP Factor</h3>
          <h4>
            - Gestion de parc informatique
            <br />- Gestion des accès Active Directory
          </h4>
        </div>
        <div class="item">
          <h4 class="years">8 mois - 2021</h4>
          <h1 class="activity2">Alternance</h1>
          <h5 class="description">Ingénieur en IA et Test</h5>
          <h3>📍 Ausy</h3>
          <h4>
            - Test des technologies de tests <br />- Test des logiciels de tests{" "}
            <br />- Recherche et développement
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Experience;
