import Header from "./component/Header";
import Pres from "./component/Presentation";
import Parcours from "./component/Parcours";
import Experience from "./component/Experiences";
import Projet from "./component/Projet";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import "./App.css";

function App() {
  return (
    <div class="bg">
      <Header></Header>
      <Pres></Pres>
      <Parcours></Parcours>
      <Experience></Experience>
      <Projet></Projet>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
