import "./Contact.css";
import { init } from "@emailjs/browser";
init("i-XjJ3TWthO3Xs1RH");

function Contact() {
  return (
    <div id="contact">
      <h1>Pour me contacter</h1>
      <div class="contact-content">
        <div class="formulaire">
          <form id="contact-form">
            <h3 id="form-title">Envoyez moi un email</h3>
            <label for="email">Email</label>
            <input type="email" name="email" required="required" />
            <label for="lastname">Nom</label>
            <input type="text" name="lastname" required="required" size="20" />
            <label for="firstname" name="firstname">
              Prénom
            </label>
            <input type="text" name="firstname" required="required" size="20" />
            <label for="objet" name="objet">
              Objet
            </label>
            <input type="text" name="objet" required="required" size="100" />
            <label for="message" name="message">
              Message
            </label>
            <textarea name="message" col="30" row="10" required="required" />
            <input id="subBtn" type="submit" value="Envoyer" />
            <div id="spinner">
              <img
                src="spinner.gif"
                alt="chargement"
                width="100vh"
              />
            </div>
            <div id="response">
              <p id="text_response"></p>
            </div>
          </form>
        </div>
        <div class="moncv">
          <h3 id="cv-title">Mon CV</h3>
          <a href="github.png" target="_blank">
            <img src="cv.png" alt="cv" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
