import "./Projet.css";

function Projet() {
  return (
    <div id="projet">
      <h1 id="projet_title">Voici un aperçu de mes projets:</h1>
      <div class="projet-content">
        <h1>Développement Web</h1>
        <div class="list-projet">
          <a href="https://github.com/franck1321/IRC" target="_blank" rel="noreferrer">
            <div id="IRC" class="item">
              <h2>IRC</h2>
              <h4>Création d'un Internet Relay Chat</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="react" src="technos/react.png" />
                  <img alt="nodejs" src="technos/nodejs.png" />
                  <img alt="expressjs" src="technos/Expressjs.png" />
                  <img alt="mongodb" src="technos/mongodb.png" />
                </div>
                <div class="techno_content_txt">
                  <p>React, </p>
                  <p>Nodejs, </p>
                  <p>Expressjs, </p>
                  <p>Mongodb</p>
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/franck1321/JobBoard" target="_blank" rel="noreferrer">
            <div id="Jobboard" class="item">
              <h2>JobBoard</h2>
              <h5>Imitation d'un site de recherche d'emploi</h5>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="php" src="technos/php.png" />
                  <img alt="mysql" src="technos/mysql.png" />
                </div>
                <div class="techno_content_txt">
                  <p>PHP, </p>
                  <p>Mysql</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="projet-content">
        <h1>Développement mobile</h1>
        <div class="list-projet">
          <div id="Redditek" class="item">
            <h2>Redditek</h2>
            <h4>Application utilisant l'api Reddit</h4>
            <div class="techno">
              <p>Techno utilisé:</p>
              <div class="techno_content">
                <img alt="react-native" src="technos/react-native.png" />
              </div>
              <div class="techno_content_txt">
                <p>React, </p>
                <p>Nodejs, </p>
                <p>Expressjs, </p>
                <p>Mongodb</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="projet-content">
        <h1>Développement d'algorithme</h1>
        <div class="list-projet">
          <a href="https://github.com/franck1321/Matchstick" target="_blank" rel="noreferrer">
            <div id="Matchstick" class="item">
              <h2>Matchstick</h2>
              <h4>Reproduire le jeu des alumettes</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="c" src="technos/c.png" />
                </div>
                <div class="techno_content_txt">
                  <p>C</p>
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/franck1321/Need4Stek" target="_blank" rel="noreferrer">
            <div id="Need4Stek" class="item">
              <h2>Need4Stek</h2>
              <h4>IA manipulant une voiture de course</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="c" src="technos/c.png" />
                </div>
                <div class="techno_content_txt">
                  <p>C</p>
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/franck1321/Groundhog" target="_blank" rel="noreferrer">
            <div id="Groundhog" class="item">
              <h2>Groundhog</h2>
              <h4>Algorithme analysant les tendance météo</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="python" src="technos/python.png" />
                </div>
                <div class="techno_content_txt">
                  <p>Python</p>
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/franck1321/My_sokoban" target="_blank" rel="noreferrer">
            <div id="Sokoban" class="item">
              <h2>my_sokoban</h2>
              <h4>Reproduction du jeu Mario Sokoban</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="c" src="technos/c.png" />
                </div>
                <div class="techno_content_txt">
                  <p>C</p>
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/franck1321/my_ftp" target="_blank" rel="noreferrer">
            <div id="Ftp" class="item">
              <h2>my_ftp</h2>
              <h4>Création d'un server FTP (File Transfert Protocol)</h4>
              <div class="techno">
                <p>Techno utilisé:</p>
                <div class="techno_content">
                  <img alt="c" src="technos/c.png" />
                </div>
                <div class="techno_content_txt">
                  <p>C</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="projet-content">
        <h1>Projet de Management</h1>
        <div class="list-projet">
          <div id="IronMan" class="item">
            <h2>IronMan</h2>
            <h4>
              Projet de gestion pour construire une armure fictive d'IronMan
            </h4>
          </div>
          <div id="Org" class="item">
            <h2>Organisationnal Theory</h2>
            <h4>
              Projet de comparaison des moyens de communication en entreprise,
              entre alternant
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projet;
