import "./Presentation.css";
import { Helmet } from "react-helmet";

function Presentation() {
  return (
    <div id="presentation">
      <div id="presentation_content" class="text">
        <h3>Salut !</h3>
        <h3>
          Je suis <h1>Franck Minassian</h1>
        </h3>
        <h3>
          Actuellement Etudiant en fin d'année de licence chez <h2>Epitech</h2>, je recherche une alternance dans le domaine du développement web
        </h3>
      </div>
      <div class="arrow">
        <img src="down-arrow.svg" class="down-arrow" alt="fleche"/>
        <img src="down-arrow.svg" class="down-arrow" alt="fleche"/>
      </div>
      <Helmet>
        <script>
          {window.addEventListener("scroll", () => {
            var list;
            if (window.scrollY >= 370) {
              list = document.getElementById("presentation_content");
              list.classList.remove("text");
              list.classList.add("animated_text");
            } else {
              list = document.getElementById("presentation_content");
              list.classList.remove("animated_text");
              list.classList.add("text");
            }
          })}
        </script>
      </Helmet>
    </div>
  );
}

export default Presentation;
