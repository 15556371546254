import "./Footer.css";

function Footer() {
  return (
    <div id="Footer">
      <div className="reseaux">
        <a href="https://github.com/franck1321">
          <img alt="github" src="github.png" />
        </a>
        <a href="https://www.linkedin.com/in/franck-minassian-30a99b179/">
          <img alt="linkedin" src="linkedin.png" />
        </a>
        <a href="https://www.instagram.com/franck_min/">
          <img alt="instagram" src="instagram.png" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
