import "./Header.css";
import $ from "jquery";

$(document).ready(function () {
  $("a").on("click", function (event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top,
        },
        800,
        function () {
          window.location.hash = hash;
        }
      );
    }
  });
});

function Header() {
  return (
    <div id="Header">
      <div className="content">
        <img src="memoji.png" alt="Memoji" id="memoji" />
        <h1>Franck Minassian</h1>
        <h3>💻 Développeur junior 💻</h3>
        <a id="learn_more" href="#presentation">
          En Savoir Plus
        </a>
      </div>
    </div>
  );
}

export default Header;
