import "./Parcours.css";

function Parcours() {
  return (
    <div id="parcours">
      <h1 id="title_parcours">Formation</h1>
      <div class="line-container">
        <span class="middle-line"></span>
      </div>
      <div class="content_parcours">
        <div class="item">
          <h4 class="years">2015 - 2018</h4>
          <h1 class="activity">Bac ES</h1>
          <h3>📍 Notre Dâme de la viste</h3>
          <h4>- Spécialité économie approfondie</h4>
        </div>
      </div>
      <div class="content_parcours">
        <div class="item">
          <h4 class="years">2018 - Aujourd'hui</h4>
          <h1 class="activity2">Master</h1>
          <h5 class="description">
            Architecte logiciel / devellopeur d'application
          </h5>

          <h3>📍 Epitech</h3>
          <h4>- Spécialité économie approfondie</h4>
        </div>
      </div>
    </div>
  );
}

export default Parcours;
